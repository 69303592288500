<template>
    <div class="note-editor compulsory-note-editor">
        <div class="container" style="display:flex; justify-content: center; align-items: center;">
            <b-button @click="showPreviousScores = true" v-if="d_previousScores != null && d_previousScores.length > 0 /*&& $slots.previousScores*/">{{ $t("note-editor.show-previous-scores-button") }}</b-button>
        </div>
        <div class="container wheeler-container">
            <wheeler-note-100 
                v-model="noteEntry"
                v-if="false || (!disabled && !averageEntry)">
<!--                <capitalize class="note-entry">{{ $t('note-editor.ballet.execution') }}</capitalize>-->
            </wheeler-note-100>
            <div v-else class="average">
                    {{ averageEntry ? "A" : noteEntry }}
            </div>
        </div>

        <div class="container" style="display:flex; justify-content: center; align-items: center; margin-top:10px; margin-bottom:10px;">
            <b-form-checkbox
                switch
                size="lg"
                v-model="averageEntry"
                v-if="!disabled"
            >
                {{ $t('note-editor.compulsory.average') }}
                <info-helper>
                    {{ $t('note-editor.compulsory.average-info-helper') }}
                </info-helper>
            </b-form-checkbox>
        </div>

            <div style="display:flex; color:red; font-style:italic; font-size:larger; justify-content: center;">{{ penaltiesValue }}</div>
            
            <div style="display:flex; justify-content: center; margin-top:5px;">
                <b-button variant="warning" size="xs" @click="showPenalties=true">
                    {{ $t("note-editor.details_and_penalties") }}
                </b-button>
            </div>

        <div style="display:flex; justify-content: center;">
            <b-button
                class="endNoteEntry cancel"
                size="xs"
                @click="cancel_click"
                variant="secondary"
            >
                {{ disabled ? $t("button.back") : $t("button.cancel") }}
            </b-button>

            <b-button
                v-if='!disabled'
                class="endNoteEntry validate"
                size="xs"
                :variant="noteIsValid || penaltiesSet.zero ? 'primary' : 'danger'"
                :disabled="!noteIsValid && !penaltiesSet.zero && noteEntry!=0"
                @click="validate_click"
            >
                {{ $t("button.validate")}}
            </b-button>
        </div>


        <b-modal
            :title="$t('note-editor.details_and_penalties')"
            centered
            static
            lazy
            size="xs"
            v-model="showPenalties"
            :scrollable="true"
            class="details-and-penalties"
        >
            <template #modal-footer="{cancel}">
                <b-button size="xs" variant="primary" @click="cancel(); showPenalties=false;">
                    {{$t("button.close")}}
                </b-button>
            </template>
            
            <compulsory-note-details-editor
              v-model="cartouche"
              :compulsoryPanelVisible="compulsoryVisible"
              :compulsoryIncludeModel="compulsoryIncludeModel"
              :compulsoryIncludeText="compulsoryIncludeText"
              :commentsPanelVisible="commentsVisible"
              :compulsory_code="compulsory_code"
            >
              <template #between-compulsory-and-comments>
                <collapse-panel class="cartouche" :title="$t('note-editor.penalties')" :visible="penaltiesVisible" @expand="penaltiesVisible=true" @collapse="penaltiesVisible=false">
                    <compulsory-penalties-editor
                        v-model="penaltiesSet"
                        @change="penalties_change"
                    />
                </collapse-panel>
              </template>
            </compulsory-note-details-editor>
       
            <collapse-panel title="Mes préférencias (par défaut)" v-if="false">
              <checkbox-form-field caption='Volet "Figure" déplié' switch v-model="compulsoryVisible" />
              <checkbox-form-field caption='Volet "Pénalités" déplié' switch v-model="penaltiesVisible" />
              <checkbox-form-field caption='Volet "Notes/Commentaires" déplié' switch v-model="commentsVisible" />
            </collapse-panel>
        
        </b-modal>

        <b-modal
            title="Confirmation de saisie"
            centered
            :static="true"
            :lazy="true"
            size="xs"
            v-if="cfgConfirmNoteEntryByPopup || noteEntry == '0'"
            v-model="showConfirmationModal"
            ok-variant="success"
            ok-title="Oui"
            cancel-variant="warning"
            cancel-title="non, je veux corriger ma note"
            button-size="xs"
            class="confirm-notes-entry compulsory"
            >
            <template #modal-footer="{cancel}">
                <b-button size="xs" variant="warning" @click="cancel()">Non, je veux corriger ma note</b-button>
                <b-button size="lg" variant="success" @click="validate_click">Oui</b-button>
            </template>
            <div v-if="averageEntry || noteEntry != null">Vous avez saisi la note suivante
                <div class="noteEntryContainer">
                    <div class="noteCheck"><b>{{ averageEntry ? "A" : noteEntry }}</b></div>
                </div>
            </div>
            <div v-else>
                Vous n'avez pas entré de note
            </div>
            <div v-if="penaltiesValue != ''">{{ confirmDialog_penaltiesText }} : 
                <ul v-if="penaltiesValue.split(',').length > 1" style="display:flex; flex-direction: column; justify-content:center; font-weight:bolder; font-size: larger; color: var(--ffvlDanger)">
                    <li v-for="penalty in penaltiesValue.split(',')" :key='penalty'>{{ penalty }}</li>
                </ul>
                <div v-else style="display:flex; justify-content:center; font-weight: bolder; font-size: larger; color: var(--ffvlDanger)">
                    {{ penaltiesValue }}
                </div>
            </div>

            <div>{{ confirDialog_endQuestion }}</div>
            <i class="warning">ATTENTION ! {{ confirmDialog_warningText }}</i>
        </b-modal>

        <b-modal
            v-model="showPreviousScores"
            centered
            static
            :title="$t('note-editor.previous_scores')"
            size="xs"
        >
            <template #modal-footer>
                <b-button size="xs" variant="secondary" @click="showPreviousScores=false">
                    {{ $t("button.close") }}
                </b-button>  
            </template>
            <slot name="previousScores" v-bind:scores="d_previousScores"></slot>    
        </b-modal>        
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CompulsoryNoteDetailsEditor from './CompulsoryNoteDetailsEditor.vue';
import CompulsoryPenaltiesEditor from './CompulsoryPenaltiesEditor.vue';
import CollapsePanel from '../Panels/CollapsePanel.vue';
import CheckboxFormField from '../Panels/CheckboxFormField.vue';
import WheelerNote100 from '../WheelerNote100.vue';
//import Uppercase from '../Utilities/Uppercase.vue';
//import Capitalize from '../Utilities/Capitalize.vue';
import InfoHelper from '../InfoHelper.vue';

export default {
  components: { CompulsoryPenaltiesEditor, CompulsoryNoteDetailsEditor, CollapsePanel, CheckboxFormField, WheelerNote100/*, Uppercase, Capitalize */, InfoHelper },
    props: {
        value: { type: Number , default: null },
        average: { type: Boolean, default: false},
        disabled: { type: Boolean, default: false },
        compulsory_code: { type: String, required:true },
        //possiblePenalties: { type: Array, default: null },
        //hasPreviousScore: { type:Boolean, default: false},
        previousScores: { type: Array, default: null },
    },
    data(){
        return {
            noteEntry: this.value ,
            averageEntry: this.average,
            showConfirmationModal: false,
            penaltiesSet: { version: 1, total: 0, count: 0},
            cartouche: {
              version: 1,
            },
            showPenalties: false,
            showPreviousScores: false,
            //d_hasPreviousScore: this.hasPreviousScore,
            d_previousScores: this.previousScores || [],
        };
    },
    computed:{
        ...mapGetters('config', ['cfgCompulsoryNoteEditor_compulsoryPaneVisible', 'cfgCompulsoryNoteEditor_compulsoryPaneIncludeModel', 'cfgCompulsoryNoteEditor_compulsoryPaneIncludeText', 'cfgCompulsoryNoteEditor_penaltiesPaneVisible', 'cfgCompulsoryNoteEditor_commentsPaneVisible']),

        compulsoryVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneVisible (newVal);}
        },
        compulsoryIncludeModel:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneIncludeModel; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneIncludeModel (newVal);}
        },
        compulsoryIncludeText:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneIncludeText; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneIncludeText (newVal);}
        },
        penaltiesVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_penaltiesPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_penaltiesPaneVisible (newVal);}
        },
        commentsVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_commentsPaneVisible (newVal);}
        },
        noteIsValid(){
            return (this.penaltiesSet != null && this.penaltiesSet.zero) || this.averageEntry || (this.noteEntry != null && this.noteEntry != "");
        },
        confirmDialog_penaltiesText(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return '';
          var prefix = (this.averageEntry || this.noteEntry != null) ? "Et" : "Mais";
          if(this.penaltiesSet.count == 1)
            return prefix + " la pénalité suivante";
          return prefix + " les pénalités suivantes";
        },
        confirDialog_endQuestion(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return "Confirmez-vous cette note ?";
          else if(this.penaltiesSet.count == 1)
            return "Confirmez-vous cette note et cette pénalité ?";
          return "Confirmez-vous cette note et ces pénalités ?";
        },
        confirmDialog_warningText(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return "Une fois confirmée, votre note ne pourra plus être modifiée (ni aucune pénalité ajoutée).";
          else if(this.penaltiesSet.count == 1)
            return "Une fois confirmées, votre note et la pénalité posée ne pourront plus être modifiées (ni aucune autre pénalité ajoutée).";
          return "Une fois confirmées, votre note et les pénalités posées ne pourront plus être modifiées (ni aucune autre pénalité ajoutée).";
        },
        penaltiesValue(){
            if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
                return "";

            var ret = [];
            if(this.penaltiesSet.absenceDebutOuFin) ret.push('Absence de début ou de fin');
            if(this.penaltiesSet.depassementTpsPrepa) ret.push('Dépassement du temps de préparation');
            if(this.penaltiesSet.mauvaiseFigure) ret.push('Présentation de la mauvaise figure');
            if(this.penaltiesSet.crash) ret.push('Crash');
            if(this.penaltiesSet.contacts) ret.push('Contact(s) accidentel(s)');

            if(this.penaltiesSet.sortieTerrain) ret.push('Vol/déplacement au-dessus des limites du terrain');

            return "".concat(ret);
        }
    },
    methods:{
        ...mapMutations('config', ['setCfgCompulsoryNoteEditor_compulsoryPaneVisible', 'setCfgCompulsoryNoteEditor_compulsoryPaneIncludeModel', 'setCfgCompulsoryNoteEditor_compulsoryPaneIncludeText', 'setCfgCompulsoryNoteEditor_penaltiesPaneVisible', 'setCfgCompulsoryNoteEditor_commentsPaneVisible', ]),
       penalties_change(){
            var ret = 0;
            var cnt = 0;
            var zero = false;
            if(this.penaltiesSet.absenceDebutOuFin) { zero=true; cnt++; }
            if(this.penaltiesSet.depassementTpsPrepa) {ret += 5; cnt++; }
            if(this.penaltiesSet.mauvaiseFigure) { zero=true; cnt++; }
            if(this.penaltiesSet.crash) { zero=true; cnt++; }
            if(this.penaltiesSet.contacts) { ret+= 10; cnt++; }

            if(this.penaltiesSet.sortieTerrain) { ret+=15; cnt++; }

            this.penaltiesSet.total = ret;
            this.penaltiesSet.count = cnt;
            this.penaltiesSet.zero = zero;
       },
       validate_click(){
            if(this.cfgConfirmNoteEntryByPopup && !this.showConfirmationModal){
                this.showConfirmationModal = true;
                return true;
            }
            var total = this.averageEntry ? "A" : this.noteEntry;
            if(total == '0' && this.penaltiesSet.count==0 && !this.showConfirmationModal)
            {
                console.log('showConfirmation');
                this.showConfirmationModal = true;
                return true;
            }
            if(!this.averageEntry && this.penaltiesSet.count > 0)
            {
                total = this.penaltiesSet.zero ? 0 : total - this.penaltiesSet.total;
                if(total < 0) total = 0;
            } 
            this.$emit('change', { entry: this.averageEntry ? "A" : parseInt(this.noteEntry), total: total, penalties: this.penaltiesSet, cartouche: this.cartouche});
            this.showConfirmationModal = false;
        },
        cancel_click(){
            this.$emit('cancel');
        }
    },
    watch:{
        //hasPreviousScore(newValue){ this.d_hasPreviousScore = newValue; },
        previousScores(newValue){ this.d_previousScores = newValue; }
    }
}
</script>

<style scoped>
    .noteEntryContainer{
        flex-direction: column;
    }
    input[type="checkbox"]{
        width:20px !important;
        height:20px !important;
    }
    modal-body{
        display: flex 1 1;
        justify-content: center;
        align-items: center;
    }
    i.warning{
        color: var(--danger);
        font-size: 0.8em;
    }
</style>

<style>
    .note-editor-dialog > tr {
        min-height:35px;
        height: 35px;
        vertical-align: center;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        .confirm-notes-entry .modal-dialog.modal-xs{
            transform: scale(0.95, 0.95) translate(-10px, -200px)
        }

        .confirm-notes-entry.compulsory .modal-dialog.modal-xs{
            transform: scale(0.95, 0.95) translate(-10px, -170px)
        }

        .details-and-penalties .modal-dialog.modal-xs > .modal-content{
            transform: scale(0.95, 0.95) translate(-10px, -50px);
        }

        .details-and-penalties.compulsory .modal-dialog.modal-xs > .modal-content{
            transform: scale(0.95,0.95) translate(-10px, -100px);
        }
    }
</style>
<style scoped>

    div.average{
        color:red;
        font-size:80px;
        width:175px !important;
        height: 100px !important;
        border:solid 2px black;
        background: linear-gradient(lightgrey, transparent, transparent, transparent, transparent, lightgrey);
        margin-left:1px;
        margin-right:1px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:7px;
    }
</style>